<template>
  <div class="library-container">
    <div class="bread"><span>企业品牌型号库</span></div>
    <el-row class="table-body">
      <div class="line clearfix">
        <h5 class="fl title">企业品牌型号库</h5>
        <div class="fr search">
          <el-input size="small" type="text" placeholder="请输入品牌型号" v-model="keywords"></el-input>
          <el-button type="primary" size="small" @click="getEntBrand">查询</el-button>
          <el-button type="primary" size="small" plain @click="addNew">新增</el-button>
        </div>
      </div>
      <div class="list">
        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column
              fixed
              prop="id"
              label="编号">
          </el-table-column>
          <el-table-column
              prop="carBrand"
              label="品牌"
              >
          </el-table-column>
          <el-table-column
              prop="carMode"
              label="型号"
              >
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="150">
            <template slot-scope="scope">
              <el-button
                  type="text"
                  size="small"
              @click="deleteEntBrand(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pages">
        <el-pagination
            background
            :hide-on-single-page="showPage"
            layout="prev, pager, next"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :total="total">
        </el-pagination>
      </div>
    </el-row>
    <edit-library ref="library" @getEntBrand="getEntBrand"></edit-library>
  </div>
</template>

<script>
import EditLibrary from "@/components/EditLibrary";
import {getEntBrand, deleteEntBrand} from '@/utils/api/brand'

export default {
  name: "index",
  components: {
    EditLibrary
  },
  data() {
    return {
      total: 0, //总条数
      pageNo: 1,
      pageSize: 10,
      showPage: true,
      keywords: "",
      tableData: []
    }
  },

  methods: {
    //新增职位
    addNew() {
      this.$refs['library'].showEdit()
    },
    getEntBrand() {
      let data = {
        "value": "",
        "page": this.pageNo,
        "rows": this.pageSize,
      }
      if(this.keywords !== ""){
        data.value = this.keywords
      }
      getEntBrand(data).then(res => {
          if(res.code ===0 ){
            this.tableData = res.data.records
            this.total = res.data.total
          }
      })
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getEntBrand()
    },
    deleteEntBrand(row){
      this.$confirm('是否删除此项, 确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id
        }
        deleteEntBrand(data).then(res =>{
          if(res.code === 0){
            this.$message.success("删除成功")
            this.getEntBrand()
          }else{
            this.$message.error(res.msg)
          }
        })
      })
    }
  },
  created() {
    this.getEntBrand()
  }
}
</script>

<style  lang="less">
.table-body {
  background-color: #FFFFFF;
  padding: 30px;
  padding-top: 0;

  .line {
    height: 90px;
    line-height: 90px;

    .title {
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }

    .search {
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;

      .el-input {
        font-size: 12px;
        margin-right: 12px;


        .el-input__inner {
          border: none;
          background-color: #FAFAFA;
          padding-left: 50px;

          &:last-child {
            padding-left: 15px;
          }
        }

        .el-input__prefix {
          width: 40px;

          &:after {
            content: '';
            width: 1px;
            height: 15px;
            background-color: #D8D7D7;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }
  }
}
</style>

