<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="45%">
    <el-form ref="form" :model="form" :label-position="labelPosition" :rules="ruleUser">
      <el-form-item label="品牌型号" :label-width="formLabelWidth" prop="brandModel">
        <el-cascader
            v-model="form.brandModel"
            placeholder="试试搜索"
            :options="options"
            @change="handleBrand"
            filterable></el-cascader>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>

import {getDict} from "@/utils/api/dict";
import {getCarModeSelectVo} from "@/utils/api/car";
import {entBrandAdd} from "@/utils/api/brand";

export default {
  name: "EditLibrary",
  data(){
    return{
      dialogFormVisible:false,
      dialogFormVisible2:false,
      title:'新增',
      formLabelWidth:"100px",
      labelPosition:"right",
      bList:[],
      brandId: '', //品牌型号id
      form:{
        brand:""
      },
      options: [],
      ruleUser: {
        brand:[
          { required: true, message: '请选择品牌型号', trigger: 'blur' },
        ],
      }
    }
  },
  methods:{
    showEdit() {
      this.title = '新增车型信息'
      this.dialogFormVisible = true
    },
    close(){
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          // const { msg } = await doEdit(this.form)
          // this.$baseMessage(msg, 'success')
          // this.$refs['form'].resetFields()
          // this.dialogFormVisible = false
          this.$emit('fetch-data')
          this.form = this.$options.data().form
        } else {
          return false
        }
      })
    },
    //获取品牌型号
    getBrandList(){
        let data = {
          value:'',enterpriseId:''
        }
        getCarModeSelectVo(data).then(res=>{
          if(res.code === 0){
            this.options = []
            this.options = res.data
          }
        })
    },
    //获取品牌
    handleBrand(value){
      this.form.carBrand = value[0]
      this.form.carMode = value[1]
      this.options.map(item=>{
        item.children.map(item1 => {
          if(item1.value === value[1]){
            this.brandId = item1.id
            return true
          }
        })
      })

    },
    handleSave(){
      entBrandAdd({brandId: this.brandId}).then(res => {
        if(res.code ===0){
          this.$message.success("添加成功")
          this.$emit('getEntBrand')
          this.close()
        }else {
          this.$message.error(res.msg)
        }
      })
    }
  },
  created() {
    this.getBrandList()
  }
}
</script>

<style  lang="less">
.el-select{
  width: 60%;
  .el-input{
    width: 100%;
  }
}
</style>
