/**
 * @notice 企业与车辆品牌对应关系
 * @author hhq
 */
import http from "../http"
//新增车辆品牌
export const entBrandAdd =(params)=>{
    return http.postRegister('/app/enter/brand/add',params)
}
//获取企业车辆品牌关联
export const getEntBrand =(params)=>{
    return http.postRequest('/app/enter/brand/page',params)
}

//删除企业车辆品牌关联
export const deleteEntBrand =(params)=>{
    return http.postUrlParams('/app/enter/brand/delete',params)
}